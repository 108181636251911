import { graphql } from 'gatsby';
import * as React from 'react';

import { formatInfoLinks, formatMenuLinks } from '../../utils/helpers';
import { ICategoryProps } from '../../utils/types';

import { CategoryComponent } from './CategoryComponent';

const CategoryTemplate = ({ data, location }: ICategoryProps): JSX.Element => {
  const { language } = data?.contentfulCategory;

  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, language);
  const infoLink = formatInfoLinks(
    data.allContentfulPrivacyAndToS.nodes,
    language
  );

  return (
    <CategoryComponent
      menuLinks={menuLink}
      infoLinks={infoLink}
      staticLocation={location}
      vacationBanner={data.contentfulVacationBanner}
      {...data?.contentfulCategory}
    />
  );
};

export const query = graphql`
  query CategoryQuery($slug: String, $language: String) {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        ...category
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      title
      subHeading
      language
      headerType
      description {
        description
      }
      sectionId
      tiles {
        ...tileElementfragment
      }
      clickToAction {
        title
        subTitle
        href: link
      }
      featuredImage {
        title
        gatsbyImageData(
          width: 2560
          quality: 100
          breakpoints: [450, 760, 1024, 1296, 1440, 1920, 2560]
          sizes: "(max-width: 450px) 450px,(max-width: 768px) 760px, (max-width:1023px) 1024px, (max-width: 1439px) 1440px, (max-width: 1920px) 1920px, 2560px"
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
      description {
        description
      }
      meta {
        name
        content
      }
      pageContent {
        raw
        references {
          ...allBlocks
        }
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: $language } }) {
      nodes {
        title
        language
        url: slug
      }
    }
    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default CategoryTemplate;
