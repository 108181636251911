import * as React from 'react';

import {
  HeadMeta,
  HomeHero,
  IClickToActionProps,
  IInfoLink,
  IMenuLink,
  Layout,
  MainHero,
  SmallHero,
} from '../../components';
import { RichTextRender } from '../../components/RichTextRender';
import { LanguageContext } from '../../context/LanguageContext';
import { IContentfulTileElement } from '../../utils/fragments/tileElementFragment';
import { RichTextElements } from '../../utils/fragments/types';
import {
  IContentfulVacationBanner,
  ICustomGatsbyImageData,
  ILocation,
  IMetaTagField,
} from '../../utils/types';

interface IContentfulCategory {
  title: string;
  subHeading: string;
  headerType: string;
  featuredImage?: ICustomGatsbyImageData;
  order: number;
  description: {
    description: string;
  };
  tiles: IContentfulTileElement[] | null;
  sectionId: string;
  pageContent: {
    raw: string;
    references: RichTextElements[];
  };
  meta?: IMetaTagField[];
  clickToAction?: IClickToActionProps;
  language: 'De' | 'En';
  vacationBanner?: IContentfulVacationBanner;
}

export interface ICategoryComponentProps extends IContentfulCategory {
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
  staticLocation?: ILocation;
}

export const CategoryComponent: React.FunctionComponent<ICategoryComponentProps> = (
  props
) => {
  return (
    <LanguageContext.Provider value={props.language}>
      <Layout
        links={props.menuLinks}
        infoLinks={props.infoLinks}
        vacationBanner={props.vacationBanner}
      >
        <HeadMeta title={props.title} meta={props.meta} lang={props.language.toLowerCase()} />
        {
          {
            'Home Hero': (
              <HomeHero
                title={props.title}
                image={props.featuredImage}
                subTitle={props.subHeading}
              />
            ),
            'Small Hero': (
              <SmallHero
                title={props.title}
                featuredImage={props.featuredImage}
                subheading={props.subHeading}
                location={props.staticLocation}
              />
            ),
            'Main Hero': (
              <MainHero
                title={props.title}
                image={props.featuredImage}
                description={props.description}
                sectionId={props.sectionId}
                location={props.staticLocation}
                tiles={props.tiles}
                clickToActionTile={props.clickToAction}
              />
            ),
          }[props.headerType]
        }
        {props.pageContent && <RichTextRender content={props.pageContent} />}
      </Layout>
    </LanguageContext.Provider>
  );
};
